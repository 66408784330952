document.addEventListener('DOMContentLoaded', function () {
	// Маска полей ввода
	const phoneInputs = document.getElementsByClassName('input_phone');
	const phoneMaskOptions = [
		// mask: '+{7} (000) 000-00-00',
		{
			mask: '+{7} (000) 000-00-00',
			startsWith: '+7',
			lazy: true,
			country: 'Russia'
		},
	];

	Array.from(phoneInputs).forEach((input) => {
		// console.log(el.tagName);
		IMask(input, phoneMaskOptions);
	});
});

// Табы
document.addEventListener('DOMContentLoaded', function () {
	var priceTabs = new Tabby('[data-price-tabs]');
});

// Десктопная липкая шапка
$(window).scroll(function () {
	let stickyHeaderDesktop = $('.js-desktop-sticky-header');

	if ($(this).scrollTop() > 300) {
		stickyHeaderDesktop.addClass("js-desktop-sticky-header--sticky");
	} else {
		stickyHeaderDesktop.removeClass("js-desktop-sticky-header--sticky");
	}
});

// Мобильная липкая шапка
$(window).scroll(function () {
	let stickyHeader = $('.js-mobile-sticky-header');

	if ($(this).scrollTop() > 150) {
		stickyHeader.addClass("js-mobile-sticky-header--sticky");
	} else {
		stickyHeader.removeClass("js-mobile-sticky-header--sticky");
	}
});

// BackToTop виджет
document.addEventListener('DOMContentLoaded', function () {
	if ($('#back-to-top').length) {
		let scrollTrigger = 400 // px
		// 	let backToTop = function () {
		// 		var scrollTop = $(window).scrollTop();
		// 		if (scrollTop > scrollTrigger) {
		// 			$('#back-to-top').addClass('show');
		// 		} else {
		// 			$('#back-to-top').removeClass('show');
		// 		}
		// 	};
		// 	backToTop();
		// 	$(window).on('scroll', function () {
		// 		backToTop();
		// 	});
		// 	$('#back-to-top').on('click', function (e) {
		// 		e.preventDefault();
		// 		$('html,body').animate({
		// 			scrollTop: 0
		// 		}, 700);
		// 	});

		const scrollBtn = document.querySelector("#back-to-top");

		const btnVisibility = () => {
			// console.log(scrollBtn.classList)
			if (window.scrollY > scrollTrigger) {
				// scrollBtn.style.visibility = "visible";
				// scrollBtn.classList.add = "show"
				$('#back-to-top').addClass('show')
			} else {
				// scrollBtn.classList.remove = "show";
				$('#back-to-top').removeClass('show')
			}
		};

		document.addEventListener("scroll", () => {
			btnVisibility();
		});

		scrollBtn.addEventListener("click", () => {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
	}
});